// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-platform-analytics-tsx": () => import("./../../../src/pages/platform/analytics.tsx" /* webpackChunkName: "component---src-pages-platform-analytics-tsx" */),
  "component---src-pages-platform-continuous-integration-tsx": () => import("./../../../src/pages/platform/continuous-integration.tsx" /* webpackChunkName: "component---src-pages-platform-continuous-integration-tsx" */),
  "component---src-pages-platform-ecosystem-tsx": () => import("./../../../src/pages/platform/ecosystem.tsx" /* webpackChunkName: "component---src-pages-platform-ecosystem-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-products-nitro-tsx": () => import("./../../../src/pages/products/nitro.tsx" /* webpackChunkName: "component---src-pages-products-nitro-tsx" */),
  "component---src-pages-services-advisory-tsx": () => import("./../../../src/pages/services/advisory.tsx" /* webpackChunkName: "component---src-pages-services-advisory-tsx" */),
  "component---src-pages-services-support-tsx": () => import("./../../../src/pages/services/support.tsx" /* webpackChunkName: "component---src-pages-services-support-tsx" */),
  "component---src-pages-services-training-tsx": () => import("./../../../src/pages/services/training.tsx" /* webpackChunkName: "component---src-pages-services-training-tsx" */),
  "component---src-templates-blog-article-template-tsx": () => import("./../../../src/templates/blog-article-template.tsx" /* webpackChunkName: "component---src-templates-blog-article-template-tsx" */),
  "component---src-templates-blog-articles-by-tag-template-tsx": () => import("./../../../src/templates/blog-articles-by-tag-template.tsx" /* webpackChunkName: "component---src-templates-blog-articles-by-tag-template-tsx" */),
  "component---src-templates-blog-articles-template-tsx": () => import("./../../../src/templates/blog-articles-template.tsx" /* webpackChunkName: "component---src-templates-blog-articles-template-tsx" */),
  "component---src-templates-default-article-template-tsx": () => import("./../../../src/templates/default-article-template.tsx" /* webpackChunkName: "component---src-templates-default-article-template-tsx" */),
  "component---src-templates-doc-article-template-tsx": () => import("./../../../src/templates/doc-article-template.tsx" /* webpackChunkName: "component---src-templates-doc-article-template-tsx" */)
}

